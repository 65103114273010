
import { defineComponent, reactive, computed, watchEffect, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AppSelect from '@/components/atoms/AppSelect.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import ItemListView from '@/components/organisms/ItemListView.vue'
import PageNavigation from '@/components/molecules/PageNavigation.vue'
import IconLoading from '@/components/atoms/IconLoading.vue'
import { useI18n } from 'vue-i18n'
import { branchDisplayList } from '@/data/selector/branchDisplayList'
import { displayModeList } from '@/data/selector/displayModeList'
import { getSelector } from '@/data/selector/getSelector'
import { ItemTree } from '@/data/@types/ItemTree'
import { ContentInfo } from '@/data/@types/ContentInfo'
import toLocale from '@/helpers/util/toLocale'
import { sessionCheck } from '@/domain/session/sessionCheck'

export default defineComponent({
  components: {
    AppSelect,
    AppButton,
    ItemListView,
    PageNavigation,
    IconLoading,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'
    const downloadingFlag = computed(() => store.getters.branchDownloadProcessingStatus === 'downloading')

    const state = reactive({
      displayedItemCount: 20,
      displayMode: 'list',
      pageNumber: 0,
    })

    watch(() => state.displayedItemCount, () => {
      state.pageNumber = 0
    })

    watchEffect(() => {
      store.dispatch('branchItemChildrenSearch', {
        pageNum: state.pageNumber,
        pageSize: state.displayedItemCount,
        // TODO 以下、PIDの自然数取得メソッドを切り出す
        pid: props.item.pid.split('/').slice(-1).toString(),
      })
    })

    const branchDisplayLocale = computed(() => getSelector('branchDisplayList', branchDisplayList, i18n.t))

    const displayModeLocale = computed(() => getSelector('displayModeList', displayModeList, i18n.t))

    const total = computed(() => {
      return store.getters.BranchChildrenTotalHits
    })

    const start = computed(() => {
      return state.pageNumber * state.displayedItemCount
    })

    const end = computed(() => {
      return Math.min(start.value + state.displayedItemCount, total.value)
    })

    const itemList = computed(() => {
      return store.getters.BranchChildrenList
    })

    const pageNavigationIndex = computed(() => {
      const pageCount = Math.ceil(total.value / state.displayedItemCount)
      return Array(pageCount).fill(0).map((_, i) => (i + 1).toString())
    })

    // const getTree = async () => {
    store.dispatch('getTree', props.item.pid)
    // }
    // const isGetTreeProcessing = ref(store.getters.getTreeProcessing)
    const isGetTreeProcessing = computed(() => {
      return store.getters.getTreeProcessing
    })

    /**
     * 一括ダウンロード押下の処理
     */
    const downloadBranch = async () => {
      const cancelDownload = () => {
        store.commit('UPDATE_BRANCH_DOWNLOAD_STATUS', {
          status: 'error',
          fixedText: i18n.t('header.toaster.bulk_download_error'),
        })
      }
      if (!await sessionCheck(route.fullPath, cancelDownload)) return

      const downloadParam: any = {
        item: trimmingOfItemTree(),
      }
      await store.dispatch('getDownloadBranchUrl', {
        request: downloadParam,
        fixedText: i18n.t('header.toaster.bulk_download_limit_error'),
      })
      const downloadUrl = store.getters.downloadBranchUrl

      if (downloadUrl && Object.keys(downloadUrl).length) {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = downloadUrl
        a.click()
        a.remove()
      }
    }

    /**
     * 長い文字列の文字列の切り抜き処理
     */
    const trimmingOfItemTree = () => {
      // itemTree内の itemTitle などが長すぎることで一括ダウンロードが失敗するため、指定バイト数を超える場合には切り抜く
      const itemTree: ItemTree = store.getters.getItemTree
      itemTree.itemTitle = cutAtSpecifiedBytes(itemTree.itemTitle, 30)
      if (!itemTree.children) return itemTree
      itemTree.children.forEach((child: ItemTree) => {
        child.itemTitle = cutAtSpecifiedBytes(child.itemTitle, 10)
        child.bundleTitle = cutAtSpecifiedBytes(child.bundleTitle, 10)
        if (!child.contents) return
        child.contents.forEach((content: ContentInfo) => {
          if (!content.originalFileName) return
          const withoutExtensionOfOriginalFileName = content.originalFileName.slice(0, content.originalFileName.lastIndexOf('.'))
          const extension = content.originalFileName.slice(content.originalFileName.lastIndexOf('.'))
          content.originalFileName = cutAtSpecifiedBytes(withoutExtensionOfOriginalFileName, 51) + extension
        })
      })
      return itemTree
    }

    /**
     * 引き渡された文字列を指定バイト数までで切り抜いて返却
     */
    const cutAtSpecifiedBytes = (str: string, checkByteLength: number) => {
      if (!str) return ''
      let byteLength = 0
      let trimmingStr = ''
      for (const character of Array.from(str)) {
        if (byteLength >= checkByteLength) break
        byteLength += (new Blob([character])).size
        trimmingStr += character
      }
      return trimmingStr
    }

    const isAllowedDownload = computed(() => store.getters.isAllowedDownload)

    return {
      state,
      itemList,
      branchDisplayLocale,
      displayModeLocale,
      pageNavigationIndex,
      total,
      start,
      end,
      isGetTreeProcessing,
      downloadBranch,
      downloadingFlag,
      toLocale,
      isRekion,
      isAllowedDownload,
    }
  },
})
